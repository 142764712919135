import React from 'react'

import Edit_Page from './Edit_Page'

const Edit_About = () => {
    return (
        < Edit_Page collection="about" />
    );
}

export default Edit_About;