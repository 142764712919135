import React from 'react'

import Edit_Page from './Edit_Page'

const Edit_Design = () => {
    return (
        < Edit_Page collection="menus" child="design" />
    );
}

export default Edit_Design;