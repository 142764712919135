import React from 'react'

import Edit_Page from './Edit_Page'

const Edit_AirConditioning = () => {
    return (
        < Edit_Page collection="menus" child="ac" />
    );
}

export default Edit_AirConditioning;